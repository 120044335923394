// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/TWb8Bsnu4";

const enabledGestures = {J0NFWbVaF: {hover: true}};

const cycleOrder = ["J0NFWbVaF"];

const serializationHash = "framer-37aPc"

const variantClassNames = {J0NFWbVaF: "framer-v-tfaabx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, nHAipprt8: link ?? props.nHAipprt8, Xq9lV5F2L: title ?? props.Xq9lV5F2L ?? "Slate Footer Link"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Xq9lV5F2L, nHAipprt8, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "J0NFWbVaF", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={nHAipprt8} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-tfaabx", className, classNames)} framer-lams47`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"J0NFWbVaF"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"J0NFWbVaF-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-12146da"} data-styles-preset={"TWb8Bsnu4"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-39f105c0-23af-4ff5-9759-7f70a5499c1e, rgb(158, 162, 173)))"}}>Slate Footer Link</motion.p></React.Fragment>} className={"framer-1rp9tt2"} data-framer-name={"Slate Footer Link"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"HXGV9ZCTu"} style={{"--extracted-r6o4lv": "var(--token-39f105c0-23af-4ff5-9759-7f70a5499c1e, rgb(158, 162, 173))"}} text={Xq9lV5F2L} variants={{"J0NFWbVaF-hover": {"--extracted-r6o4lv": "var(--token-4ba96c1e-b647-438d-9d5c-3ff29696d43f, rgb(235, 236, 239))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"J0NFWbVaF-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-12146da"} data-styles-preset={"TWb8Bsnu4"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-4ba96c1e-b647-438d-9d5c-3ff29696d43f, rgb(235, 236, 239)))"}}>Slate Footer Link</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-37aPc.framer-lams47, .framer-37aPc .framer-lams47 { display: block; }", ".framer-37aPc.framer-tfaabx { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: 172px; }", ".framer-37aPc .framer-1rp9tt2 { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-37aPc.framer-tfaabx { gap: 0px; } .framer-37aPc.framer-tfaabx > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-37aPc.framer-tfaabx > :first-child { margin-left: 0px; } .framer-37aPc.framer-tfaabx > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 172
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"UtcWOttYg":{"layout":["fixed","auto"]}}}
 * @framerVariables {"Xq9lV5F2L":"title","nHAipprt8":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framertio7zc_Bw: React.ComponentType<Props> = withCSS(Component, css, "framer-37aPc") as typeof Component;
export default Framertio7zc_Bw;

Framertio7zc_Bw.displayName = "Button/Footer Link";

Framertio7zc_Bw.defaultProps = {height: 24, width: 172};

addPropertyControls(Framertio7zc_Bw, {Xq9lV5F2L: {defaultValue: "Slate Footer Link", displayTextArea: false, title: "Title", type: ControlType.String}, nHAipprt8: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framertio7zc_Bw, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})